import React from 'react';
import Head from 'next/head';
import Link from 'next/link';
import Image from 'next/image';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/firebaseApp';
import Home from './home';
import { Loader } from '../components';

export default function OnBoardPage() {  
  const [user, loading] = useAuthState(auth);
  if(loading) return <div className="h-screen pt-80"><Loader /></div>

  const Story = ({condition}) => {
    console.log("pppp",condition)
    return (
      <div className="">
        <div className='h-96 sticky'>
          <div className="fixed opacity-100 w-full max-h-64 h-2/3 top-0 z-0">
            <div className="relative w-[50rem] h-[36rem] md:w-[66rem] md:h-[56rem] lg:w-[110rem] lg:h-[46rem] bg-gradient-to-r from-red-500 to-purple-700">
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="absolute bg-gradient-to-r from-white via-pink-500 to-white text-transparent bg-clip-text text-center z-20 font-bold w-full top-40 pb-5 text-8xl md:text-9xl">Monet</div>
          {!condition ? <div><div className="absolute text-center z-20 font-medium w-full top-80 pb-5 text-pink-100 text-lg md:text-xl px-5">Maximize your creative potential and grow your business</div>
            <Link href="https://apps.apple.com/us/app/the-monet/id6502591329" className="absolute w-full flex items-center justify-center z-20 pt-10 top-96">
            <div className="flex flex-row bg-pink-500 rounded-md text-black font-semibold py-2 px-5 items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} style={{ fill: "#fff", marginRight: '8px' }} viewBox="0 0 30 30">
                <path d="M25.565 9.785c-.123.077-3.051 1.702-3.051 5.305.138 4.109 3.695 5.55 3.756 5.55-.061.077-.537 1.963-1.947 3.94-1.119 1.703-2.361 3.42-4.247 3.42-1.794 0-2.438-1.135-4.508-1.135-2.223 0-2.852 1.135-4.554 1.135-1.886 0-3.22-1.809-4.4-3.496-1.533-2.208-2.836-5.673-2.882-9-.031-1.763.307-3.496 1.165-4.968 1.211-2.055 3.373-3.45 5.734-3.496 1.809-.061 3.419 1.242 4.523 1.242 1.058 0 3.036-1.242 5.274-1.242.966.001 3.542.292 5.137 2.745zM15.001 6.688c-.322-1.61.567-3.22 1.395-4.247 1.058-1.242 2.729-2.085 4.17-2.085a6.003 6.003 0 0 1-1.533 4.339c-.935 1.242-2.545 2.177-4.032 1.993z" />
              </svg>
              <div className="text-white">Download the App</div>
            </div>
          </Link>
          </div> :
            <div className="absolute text-center z-20 font-medium w-full top-80 px-6 lg:px-8 text-left">
              <h2 className="text-white text-center font-semibold text-2xl md:text-3xl p-5">Design your virtual space {user.displayName} and share your AR experiences</h2>
              <Link href="https://apps.apple.com/us/app/the-monet/id6502591329" className="w-full flex items-center justify-center z-20 pt-10 top-96">
              <div className="flex flex-row bg-black rounded-md text-black font-semibold py-2 px-5 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} style={{ fill: "#fff", marginRight: '8px' }} viewBox="0 0 30 30">
                  <path d="M25.565 9.785c-.123.077-3.051 1.702-3.051 5.305.138 4.109 3.695 5.55 3.756 5.55-.061.077-.537 1.963-1.947 3.94-1.119 1.703-2.361 3.42-4.247 3.42-1.794 0-2.438-1.135-4.508-1.135-2.223 0-2.852 1.135-4.554 1.135-1.886 0-3.22-1.809-4.4-3.496-1.533-2.208-2.836-5.673-2.882-9-.031-1.763.307-3.496 1.165-4.968 1.211-2.055 3.373-3.45 5.734-3.496 1.809-.061 3.419 1.242 4.523 1.242 1.058 0 3.036-1.242 5.274-1.242.966.001 3.542.292 5.137 2.745zM15.001 6.688c-.322-1.61.567-3.22 1.395-4.247 1.058-1.242 2.729-2.085 4.17-2.085a6.003 6.003 0 0 1-1.533 4.339c-.935 1.242-2.545 2.177-4.032 1.993z" />
                </svg>
                <div className="text-white">Download the App</div>
              </div>
            </Link>
            </div>
          }
        </div>
        <div className="mt-52 bg-black">
          <div className="flex space-x-5">
            <div className="w-full bg-black flex-col z-30 pb-7">
              <div className="w-full bg-black pb-20">
                <div className="relative px-10 w-full flex justify-center items-center">
                  <Image
                    unoptimized
                    src="/awards.png"
                    alt="Monet awards. Create virtual exhibition in minutes with Monet"
                    priority
                    width={1000}
                    height={600}
                    className="pt-14 sticky object-contain max-w-full h-auto"
                  />
                </div>          
              </div>
              <h2 className="mx-7 text-white text-center font-bold text-4xl pb-10">Empowering the world's creatives</h2>
            </div>
          </div>
          <div className="flex justify-center md:flex-row flex-col">
            <div className="bg-black pt-10 w-full flex z-30 flex-col">
              <div className="mx-12 pb-10">
                <div className="bg-gradient-to-r from-pink-200 via-pink-400 to-pink-300 text-transparent bg-clip-text text-xl md:text-3xl">Curate Immersive Exhibitions</div>
                <div className="opcity-80 pl-3 pt-2 text-white">Utilize Monet's technology to effortlessly digitize your objects and curate hyper realistic virtual exhibitions, providing viewers with immersive experiences that transcend physical limitations.</div>
              </div>
              <div className="mx-12">
                <div className="bg-gradient-to-r from-blue-200 via-blue-400 to-blue-300 text-transparent bg-clip-text text-xl md:text-3xl">Talks, Tours, and Research</div>
                <div className="opacity-80 pl-3 pt-2 text-white">Maximize your collection's potential with live or on-demand talks and tours. By digitizing collections and revisiting past exhibitions, it reduces the risk of rare objects handling and expands educational outreach globally.</div>
              </div>
            </div>
          <div className="bg-black  pb-40 pt-10 w-full flex z-30 flex-col">
            <div className="mx-12 pb-10">
              <div className="bg-gradient-to-r from-green-200 via-green-400 to-green-300 text-transparent bg-clip-text text-xl md:text-3xl">Enhance Engagement</div>
              <div className="opacity-80 pl-3 pt-2 text-white">Our platform empowers artists, galleries, institutions, and collectors to showcase their collections across web, mobile, and Virtual Reality (VR) environments</div>
            </div>
            <div className="mx-12 pb-10">
              <div className="bg-gradient-to-r from-red-200 via-red-600 to-blue-300 text-transparent bg-clip-text text-xl md:text-3xl">Centralize your operations</div>
              <div className="opacity-80 pl-3 pt-2 text-white">Discover, acquire, and manage your digital collection with ease on a single platform. PayPal checkout provides a simple and secure experience, empowering the next wave of creative-tech</div>
            </div>
          </div>
        </div>
        </div>

      </div>
    )
  }
  return (
    <main>
      <Head>
        <title>Monet, making creativity accessible</title>
        <meta name="description" content="With Monet, you can create virtual spaces in minutes. Scan your objects into 3D and add them to your space and deploy it everywhere"/>
      </Head>
      {user ? <Story condition={true} /> : <Story condition={false} />}
      {/*user ? <Home user={user}/> : <Story />*/}
    </main>
  );
}