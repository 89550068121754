import Head from 'next/head';
import { useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import useSWRInfinite from 'swr/infinite';
import dynamic from 'next/dynamic';

const Update = dynamic(() => import('../components/Update'), {
  loading: () => 'Loading...',
});

export default function Home({user}) {
  const PAGE_SIZE = 20; 
  const [ps, setPs] = useState(1);
  const [dataLength, setDatalength] = useState(0);
  const [lastpost, setLastPost] = useState(0);

  const loadMoreBtnRef = useRef(null);

  const fetcher = async (offset) => {
    const res = [];
    const posts = await fetch(`${process.env.NEXT_PUBLIC_DATABASE_URL}/posts.json?orderBy="createdAt"${offset}`).then((res) => res.json())
    for(const key in posts){
      if(!res.includes(posts[key])){
        res.push(posts[key])
      }
    }
    return res;
  };

  const getKey = (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.length) return null;
    const offset = PAGE_SIZE * ps;
    
    return `&limitToLast=${offset}`
  };

  const options = { 
    revalidateOnMount: true,
  };

  const { data, error, size, setSize } = useSWRInfinite(getKey, fetcher, options);

  useEffect(() => {
    if (data && loadMoreBtnRef.current) {
      loadMoreBtnRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [data]);

  const postAll = data ? data.flat() : [];

  const sortedPosts = postAll.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
  const isLoadingInitialData = !data && !error;
  const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');

  const handleLoadMore = (e) => {
    e.preventDefault();
    if (!isLoadingMore) {
      setSize(size+1); 
      setLastPost(sortedPosts.length-1);
      setPs((prev)=> prev+1);
      setDatalength(sortedPosts.length)
    }
  };

  return ( 
    <main>
      <Head>
        <title>Monet Home Page. Making creativity accessible</title>
        <meta name="description" content="With Monet, you can create virtual spaces in minutes. Scan your objects into 3D and add them to your space and deploy it everywhere"/>
        <meta property="og:image" content="https://media.graphassets.com/output=format:jpg/resize=height:800,fit:max/oSU2XDO3S0CtMai113wS" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:type" content="image/png" />
      </Head>
      <div>
        <div className="pt-24 items-center">
        <h1 className="text-center text-4xl py-20 bg-gradient-to-r from-blue-400 via-white to-blue-500 text-transparent bg-clip-text font-extrabold">Stay Creative.</h1>
        <div className="flex h-full items-center justify-center pt-10 mb-8">
            <div className="pb-16 mx-1 md:mx-10 sm:pb-24 last:pb-0">
              <div>  
                {sortedPosts.length > 0 && sortedPosts.map((post, index) => {
                  if(index === lastpost && index !== 0){
                    return (
                      <div ref={loadMoreBtnRef} key={index}>
                      {!post.isBlog ? <div><Update arr={post} /></div> :
                      <div><Update arr={post}/></div>}
                      </div>
                    );
                  }
                  return (
                    <div key={index}>
                    {!post.isBlog ? <div><Update arr={post} /></div> :
                    <div><Update arr={post}/></div>}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>  
      {!isLoadingMore && sortedPosts.length <= dataLength ? (
        <div className="flex items-center justify-center">
          <div className="text-white">No more posts to load</div>
        </div>
      ) :
      <div className="flex items-center justify-center">
        {sortedPosts.length >= 1 && <button className="transition duration-700 text-white border px-1 py-1 hover:border-blue-500 hover:text-blue-400" onClick={handleLoadMore}>Load more</button>}
      </div>
      }
      <ToastContainer
      className="z-50"
      position="top-center"
      autoClose={4000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    /> 
    </main>
  );
};
